import React, { useEffect } from "react";
import { MESSAGE_TYPE } from "../models/global";
import '../css/Message.css';
import $ from 'jquery';

interface propsType {
    msg: string;
    type: MESSAGE_TYPE;
    close: () => void;
}

const Message = (props: propsType) => {
    const toOpen = () => {
        $('.message-container').animate(
            { top: '20px' },
            300,
            () => console.log("Modal opened")
        );
    };
    const toClose = () => {
        $('.message-container').animate(
            { top: '-300px' },
            300,
            () => props.close()
        );
    };

    useEffect(() => {
        toOpen();
        const timeoutId = setTimeout(() => {
            toClose();
        }, 7000);
        return () => clearTimeout(timeoutId);
    }, [props]);

    return (
        <div className="message-container">
            <div className={`msg-box ${props.type === MESSAGE_TYPE.INFORMATION ? 'msg-info' : props.type === MESSAGE_TYPE.WARNING ? 'msg-warning' : props.type === MESSAGE_TYPE.SUCCESS ? 'msg-success' : 'msg-critical'}`}>
            <div dangerouslySetInnerHTML={{ __html: props.msg }} />
            </div>
        </div>
    );
};

export default Message;
