import React, { useState } from "react";
import "../css/ExchangeModal.css"
import { IoClose } from "react-icons/io5";
import { FaArrowDown, FaCopy } from "react-icons/fa";
import { FaArrowUp } from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";
import { MESSAGE_TYPE, MgaMoney, Order, UsdBalance, UsdMoney, balance, cours } from "../models/global";
import { calculateMgaAmount, checkAccountNumber, checkAmount, checkPhoneNumber, createMgaMoney } from "../services/exchange";
import { copyToClipboard, formatNumber } from "../services/global";
import { IoIosWarning } from "react-icons/io";
interface propsType{
    step: number;
    choisedMoney: UsdMoney;
    setStep: (step: number) => void;
    order: Order;
    setOrder: (order: Order) => void;
    onSubmit: (e: any) => void;
    coursData: cours;
    balanceData: balance;
    mga_account?: MgaMoney;
    usd_balance?: UsdBalance;
    doneExchange: () => void;
    pusMsg: (msg: string, type: MESSAGE_TYPE) => void;
}
const ExchangeModal = (props: propsType) => {
    const [isError, setError] = useState<boolean>(false);
    const [isUsdAccountNumberError, setIsUsdAccountNumberError] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>();
    const [usdAccountNumberErrorMsg, setUsdAccountNumberErrorMsg] = useState<string>('');
    const [isMobileError, setIsMobileError] = useState<boolean>(false);
    const [mobileErrorMsg, setMobileErrorMsg] = useState<string>('');
    const handleChange = (e: any) => {
        props.setOrder({...props.order, [e.target.name]: e.target.value})
        if(e.target.name === 'amount'){
            const amount_mga = calculateMgaAmount({...props.order, amount: Number(e.target.value)}, props.choisedMoney, props.balanceData, props.coursData);
            const _checkAmount = checkAmount({...props.order, amount: Number(e.target.value), amount_mga: amount_mga}, props.choisedMoney, props.balanceData, props.coursData);
            console.log(amount_mga);
            if(_checkAmount){
                setErrorMsg(_checkAmount);
                setError(true);
            }else{
                props.setOrder({...props.order, amount_mga: amount_mga, [e.target.name]: Number(e.target.value)})
                setError(false);
            }
        } 
        else if(e.target.name === 'wallet_usd_number'|| e.target.name === 'wallet'){
            const _checkNumber = checkAccountNumber({name: props.choisedMoney.type === 'CRYPTO' && e.target.name === 'wallet' ? e.target.value : props.choisedMoney.type === 'CRYPTO' && e.target.name !== 'wallet' ? props.order.wallet! : props.choisedMoney.name, number: e.target.name === 'wallet_usd_number' ? e.target.value : props.order?.wallet_usd_number || ''});
            if(_checkNumber){
                setIsUsdAccountNumberError(true);
                setUsdAccountNumberErrorMsg(_checkNumber);
            }else{
                props.setOrder({...props.order, [e.target.name]: e.target.value});
                setIsUsdAccountNumberError(false);
            }
        }
        else if(e.target.name === 'wallet_mga_number'){
            const checkMobile = checkPhoneNumber(e.target.value);
            if(checkMobile){
                setIsMobileError(true);
                setMobileErrorMsg(checkMobile);
            }else{
                props.setOrder({...props.order, [e.target.name]: e.target.value, money_mga: createMgaMoney(e.target.value)});
                setIsMobileError(false);
            }
        }
    }

    // const handleSubmit = (e: any) => {
    //     e.preventDefault();
    // }

    return(
        <div className="exchange-modal-container">
            <div className="exchange-box">
                <button className="cancel-button" onClick={()=>props.setStep(0)}>
                    <IoClose size={25}/>
                </button>
                {props.step === 1 && 
                (<>
                    <button className="exchange-btn" onClick={()=>{
                        props.setOrder({...props.order, type: 'Dépot'});
                        props.setStep(props.step + 1);
                    }}><FaArrowDown/><span>DEPOT</span></button>
                    <button className="exchange-btn"  onClick={()=>{
                        props.setOrder({...props.order, type: 'Retrait'});
                        props.setStep(props.step + 1);
                    }}><FaArrowUp/><span>RETRAIT</span></button>
                </>)
                } 
                {props.step > 1 && 
                (<>
                    <form className="exchange-info-container" onSubmit={props.onSubmit}>
                        {props.step === 3 && (<>
                            {props.choisedMoney.type === 'CRYPTO' && (<>
                                <label>Portefeuille</label>
                                <select className="ex-input" value={props.order.wallet} name="wallet" onChange={handleChange}>
                                    <option value="BINANCE">BINANCE</option>
                                    <option value="OTHER">AUTRE</option>
                                </select>
                            </>)}
                            <label>{props.choisedMoney.type === 'CRYPTO' && props.order.wallet === 'BINANCE' ? 'ID BINANCE':`Numéro du compte ${props.choisedMoney.name}`} ($)</label>
                            <input type="text" className="ex-input" placeholder={props.choisedMoney.type === 'CRYPTO' && props.order.wallet === 'BINANCE' ? 'ID BINANCE':`Numéro du compte ${props.choisedMoney.name}`} value={props.order.wallet_usd_number} name="wallet_usd_number" onChange={handleChange}/>
                            {isUsdAccountNumberError && (<div className="error-amount">{usdAccountNumberErrorMsg}</div>)}
                            <label>Montant à {props.order.type === 'Dépot' ? 'deposer':'retirer'} ($)</label>
                            <input type="number" className="ex-input" placeholder="Montant" value={props.order.amount} name="amount" onChange={handleChange}/>
                            {isError && (<div className="error-amount">{errorMsg}</div>)}
                        </>)}
                        {props.step === 2 && (
                            <>
                            {!localStorage.getItem('user') && (<>
                            <label>Nom du client</label>
                            <input type="text" className="ex-input" placeholder="Nom du client" value={props.order.user_name} name="user_name" onChange={handleChange}/>
                            </>)}
                            <label>Numéro du mobile money</label>
                            <input type="text" className="ex-input" placeholder="Numéro du mobile money" value={props.order.wallet_mga_number} name="wallet_mga_number" onChange={handleChange}/>
                            {isMobileError && (<div className="error-amount">{mobileErrorMsg}</div>)}
                            <label>Nom du mobile money</label>
                            <input type="text" className="ex-input" placeholder="Nom du mobile money (ex: Angelo)" value={props.order.wallet_mga_name} name="wallet_mga_name" onChange={handleChange}/>
                            </>
                        )}
                        {props.step === 4 && (
                            <>
                                <div className="checkout">
                                    <div><FaCheck size={20}/></div>
                                    <strong>VERIFICATION</strong>
                                </div>
                                <p className="checkout-p"><strong>Type d'échange</strong> <span>{props.order.type}</span></p>
                                <p className="checkout-p"><strong>Money</strong> <span>{props.order.money_usd}</span></p>
                                {props.choisedMoney.type === 'CRYPTO' && (<p className="checkout-p"><strong>Wallet</strong> <span>{props.order.wallet}</span></p>)}
                                {props.choisedMoney.type === 'CRYPTO' && props.order.wallet === 'BINANCE' && (<p className="checkout-p"><strong>ID BINANCE</strong> <span>{props.order.wallet_usd_number}</span></p>)}
                                {(props.choisedMoney.type === 'FIAT' || props.order.wallet === 'OTHER') && (<p className="checkout-p"><strong>N° du compte {props.order.money_usd}</strong> <span>{props.order.wallet_usd_number}</span></p>)}
                                <p className="checkout-p"><strong>Type de money</strong> <span>{props.choisedMoney.type}</span></p>
                                <p className="checkout-p"><strong>Montant à {props.order.type === 'Dépot' ? ' déposer' : ' rétirer'}</strong> <span>{formatNumber(props.order.amount!)} $</span></p>
                                <p className="checkout-p"><strong>Correspondance en Ariary</strong> <span>{formatNumber(props.order.amount_mga!)} AR</span></p>
                                <p className="checkout-p"><strong>Numéro du compte mobile money</strong> <span>{props.order.wallet_mga_number}</span></p>
                                <p className="checkout-p"><strong>Nom du compte mobile money</strong> <span>{props.order.wallet_mga_name}</span></p>
                            </>
                        )}
                        {props.step === 5 && (
                            <div className="finish-container">
                                <div>
                                    <strong>FINALISATION</strong>
                                </div>
                                <p>
                                    Pour finaliser votre échange veuillez nous transferer la somme {props.order.type === 'Dépot' ? formatNumber(props.order.amount_mga!) + ' AR' : formatNumber(props.order.amount!) + ' $'}
                                </p>
                                {props.order.type === 'Dépot' ? (
                                    <>
                                        <p>
                                        <strong>Compte</strong> <span>{props.mga_account!.wallet_number}</span>
                                        <button type="button" className="btn-copy" onClick={()=>{
                                            copyToClipboard(props.mga_account!.wallet_number!);
                                            props.pusMsg('Copié', MESSAGE_TYPE.SUCCESS);
                                            }}>
                                            <FaCopy size={20} />
                                        </button>
                                        </p>
                                        <p>
                                        <strong>Nom du compte</strong> <span>{props.mga_account!.wallet_name}</span>
                                        </p>
                                    </>
                                ):(
                                    <>
                                    <p>
                                    <strong>{props.choisedMoney.type === 'CRYPTO' && props.order.wallet === 'BINANCE' ? 'ID BINANCE' : 'N° Compte'}</strong> <span className={props.choisedMoney.type === 'CRYPTO' && props.order.wallet !== 'BINANCE' ? 'small' : ''}>{props.choisedMoney.type === 'CRYPTO' && props.order.wallet === 'BINANCE' ? props.choisedMoney.wallet_id : props.choisedMoney.wallet_number}</span>
                                    <button type="button" className="btn-copy" onClick={()=>{
                                        copyToClipboard(props.choisedMoney.type === 'CRYPTO' && props.order.wallet === 'BINANCE' ? props.choisedMoney.wallet_id : props.choisedMoney.wallet_number);
                                        props.pusMsg('Copié', MESSAGE_TYPE.SUCCESS);
                                        }}>
                                        <FaCopy size={20} />
                                    </button>
                                    </p>
                                </>
                                )}
                                <p><span className="warning"><IoIosWarning size={20} /></span> Une fois que le transfert sera effectué, n'oubliez pas de cliquer 👇</p>
                                <button onClick={()=>props.doneExchange()} type="button" className="done-btn">DEJA EFFECTUE</button>
                            </div>
                        )}
                        {props.step < 5 && (<div className="step-btn-container">
                            <button type="button" className="prev" onClick={()=>{
                                props.setStep(props.step - 1)
                            }}>Précedent</button>
                            {((props.step === 3 && (!props.order.wallet_usd_number || !props.order.amount)) || (props.step === 3 && isError) || (props.step === 3 &&isUsdAccountNumberError)) ? (<div className="anable-button">{props.step < 4 ? 'Suivant' : 'Valider'}</div>) : ((props.step === 2 && (!props.order.wallet_mga_number || !props.order.wallet_mga_name)) || (props.step === 3 && isMobileError))? (<div className="anable-button">{props.step < 4 ? 'Suivant' : 'Envoyer'}</div>) : (<>
                            {props.step < 4 && (
                            <button type="button" className="next" onClick={()=>{
                                props.setStep(props.step + 1)
                            }}>Suivant</button>)}
                             {props.step === 4 && (<button type="submit" className="next">Valider</button>)}
                            </>)}
                        </div>)}
                    </form>
                </>)
                } 
            </div>
        </div>
    )
}

export default ExchangeModal;