import React, { useEffect } from "react";
import { propsType } from "../Navigation";
import "../css/Condition.css"
import { Helmet } from "react-helmet";

const Condition = (props: propsType)=>{
    useEffect(()=>{
        props.setIsLoading(false);
    }, [])
    return(
        <div className="condition-container">
            <Helmet>
                <meta charSet='utf-8' name="description" content="Ici, vous trouverez toutes les conditions requises pour utiliser le service d'échange Ny Exchange Mada."/>
                <title>Condition - Conditions d'utilisation de Ny Exchange Mada</title>
                <link rel="canonical" href='https://nyexchangemada.com/condition'/>
            </Helmet>
            <h2>Termes et conditions</h2>
            <div className="article">ARTICLE 1: A PROPS DE TRANSACTION</div>
            <p>
            Nous déclinons toute responsabilité en cas de pertes résultant de vos erreurs. Cependant, si une perte est imputable à une erreur de notre part, nous assumerons l'entière responsabilité de vous rembourser.
<br></br>
Cher client, avant d'effectuer des échanges avec nous, veuillez prendre le temps de lire attentivement nos termes et conditions d'utilisation ci-dessous, s'il vous plaît :
<br></br>
Tout individu peut effectuer des échanges avec nous sans exception.
<br></br>
En ce qui concerne les transactions (dépôt et/ou retrait), nous ne sommes pas responsables des pertes dues à vos erreurs. Cependant, si la responsabilité nous incombe, nous assumerons l'entière responsabilité de vous rembourser.
Veuillez vérifier le cours du jour avant chaque échange afin d'éviter les imprévus, et veuillez également vérifier les limites de transaction.
<br></br>
Nous déclinons toute responsabilité pour les transactions effectuées en dehors de notre plateforme et notre page Facebook.
<br></br>
En cas de perte d'argent ou d'implication dans une arnaque, etc., veuillez noter que cela relève de votre responsabilité et non de la nôtre.
</p>
<div className="article">ARTICLE 2: A TITRE DE RAPPEL</div>
<p>
Nous ne sommes pas responsables de l'utilisation que vous faites du dépôt que vous effectuez ni de l'origine du solde que vous retirez ; cela relève de votre seule responsabilité.
<br></br>
Les retraits provenant de sites interdits par la loi à Madagascar, tels que Coomeet, Chat pour adultes, et d'autres types similaires, ne sont pas acceptés.
<br></br>En cas de poursuites pour des soldes obtenus illégalement, nous nous retirons complètement de cette affaire.
<br></br>
Soyez vigilant face aux faux profils qui usurpent notre identité.
<br></br>
Nous sommes uniquement des revendeurs de devises numériques.
<br></br>
Nos services consistent uniquement à assurer vos dépôts et retraits de manière professionnelle, dans le but de satisfaire nos clients, rien de plus.
<br></br>
Nous n'acceptons que les transferts provenant des numéros que vous avez fournis, et non le numéro du cashpoint pour les dépôts.
</p>
<div className="article">ARTICLE 3: NOTRE ENGAGEMENT ENVERS LES DONNEES DE NOS CLIENTS</div>
<p>
Chez NY - Exchange Mada, la protection et la confidentialité des données de nos clients sont au cœur de nos préoccupations. Nous nous engageons fermement à mettre en place des mesures rigoureuses visant à assurer la sécurité de toutes les informations fournies par nos utilisateurs. Voici les principaux points de notre engagement envers les données :<br></br><br></br>
1. <strong>Confidentialité absolue</strong> : Nous garantissons la confidentialité absolue des informations personnelles de nos clients. Toutes les données, y compris les détails de compte, les transactions et autres informations sensibles, sont traitées avec la plus grande discrétion.
<br></br><br></br>
2. <strong>Pas de partage d'informations</strong> : Nous nous engageons à ne pas partager, vendre ou louer les données de nos clients à des tiers sans leur consentement explicite. Vos informations restent strictement confidentielles et ne sont utilisées que dans le cadre des services que nous vous fournissons.
<br></br><br></br>
3. <strong>Transparence </strong> : Nous vous tenons informés de notre politique de confidentialité et de tout changement éventuel apporté à celle-ci. La transparence est essentielle pour instaurer une relation de confiance avec nos clients.
<br></br><br></br>
En choisissant NY - Exchange Mada, vous bénéficiez de la tranquillité d'esprit en sachant que vos données sont traitées avec le plus grand soin et en conformité avec les normes les plus élevées en matière de sécurité et de confidentialité. Nous sommes déterminés à maintenir la confiance que vous placez en nous pour toutes vos transactions de cryptomonnaie en ariary.
</p>
</div>
    )
}

export default Condition;