import React from 'react';
import "../css/Balance.css"
import { formatNumber } from '../services/global';
import { FaBalanceScale } from "react-icons/fa";
import { balance } from '../models/global';

interface balanceType{
    BalanceData: balance
}

const Balance = (props: balanceType)=>{
    return(
        <div className='balance'>
            <div className='balance-title'><FaBalanceScale size={20}/> <span>BALANCE</span></div>
            <div className='balance-container'>
                <div className='balance-box balance-item'>
                    <div className='balance-id'>
                        <img src='https://nyexchangemada.com/api/static/1700661778.png' alt='p-logo'>
                        </img>
                        <strong>USD PAYEER</strong>
                    </div>
                    <div className='balance-content'>
                        {formatNumber(props.BalanceData.payeer > 0 ? props.BalanceData.payeer :  0)} $
                    </div>
                </div>
                <div className='balance-box balance-item'>
                    <div className='balance-id'>
                        <img src='https://nyexchangemada.com/api/static/1700661788.png' alt='pm-logo'>
                        </img>
                        <strong>USD PERFECT MONEY</strong>
                    </div>
                    <div className='balance-content'>
                    {formatNumber(props.BalanceData.pm > 0 ? props.BalanceData.pm : 0)} $
                    </div>
                </div>
                <div className='balance-box balance-item'>
                    <div className='balance-id'>
                        <img src='https://nyexchangemada.com/api/static/1699788232.bmp' alt='crypto-logo'>
                        </img>
                        <strong>CRYPTOMONEY + USDT</strong>
                    </div>
                    <div className='balance-content'>
                    {formatNumber(props.BalanceData.crypto > 0 ? props.BalanceData.crypto : 0)} $
                    </div>
                </div>
            </div>
            <div className='balance-container'>
            <div className='balance-box balance-item'>
                    <div className='balance-id'>
                        <img src='https://nyexchangemada.com/api/static/1699075362.png' alt='mvola-logo'>
                        </img>
                        <strong>M VOLA</strong>
                    </div>
                    <div className='balance-content'>
                    {formatNumber(props.BalanceData.m_vola > 0 ? props.BalanceData.m_vola  : 0)} AR
                    </div>
                </div>
                <div className='balance-box balance-item'>
                    <div className='balance-id'>
                        <img src='https://nyexchangemada.com/api/static/1699075398.jpg' alt='orange-logo'>
                        </img>
                        <strong>ORANGE MONEY</strong>
                    </div>
                    <div className='balance-content'>
                    {formatNumber(props.BalanceData.orange_money > 0 ? props.BalanceData.orange_money : 0)} AR
                    </div>
                </div>
                <div className='balance-box balance-item'>
                    <div className='balance-id'>
                        <img src='https://nyexchangemada.com/api/static/1699075408.png' alt='airtel-logo'>
                        </img>
                        <strong>AIRTEL MONEY</strong>
                    </div>
                    <div className='balance-content'>
                    {formatNumber(props.BalanceData.airtel_money > 0 ? props.BalanceData.airtel_money : 0)} AR
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Balance;