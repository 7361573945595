import React, { useEffect, useState } from 'react';
import { propsType } from '../Navigation';
import { MESSAGE_TYPE, MgaMoney, Order, UsdBalance, User } from '../models/global';
import { getAllOrder, getMgaMoney, getUsdBalance, updateMgaMoney, updateOrder, updateUsdBalance } from '../services/exchange';
import "../css/Exchange.css"
import ExchangeBox from '../components/ExchangeBox';
import { IoClose } from 'react-icons/io5';
import { IoIosWarning } from 'react-icons/io';
import { Helmet } from 'react-helmet';

const Exchange = (props: propsType)=>{
    const [orders, setOrders] = useState<Order[]>([]);
    const user: User = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!) : {}
    const [selectedOrder, setSelectedOrder] = useState<Order>({})
    const [showModal, setShowModal] = useState<boolean>(false)

    const [mga_account, setMgaAccount] = useState<MgaMoney>({})
    const [usdBalance, setUsdBalance] = useState<UsdBalance>({})

    const init = ()=>{
        if(user.id) getAllOrder(user.id).then(_list=>{
            props.setIsLoading(false);
            if(_list){
                setOrders(_list);
            }
        })
        else{
            props.setIsLoading(false);
        }
    }

    const selectOrder = (order: Order) => {
        if(!order.is_payed){
            props.setIsLoading(true);
            getMgaMoney(order.money_mga!).then(account=>{
                if(account){
                    setMgaAccount(account);
                    getUsdBalance(order.type === 'CRYPTO' ? 'CRYPTO M' : order.money_usd === 'USD PAYEER' ? 'USD PAYEER' : 'USD PM').then(balance=>{
                        if(balance){
                            props.setIsLoading(false);
                            setUsdBalance(balance);
                            setSelectedOrder(order);
                            setShowModal(true);
                        }
                    })
                }
            })
        }else{
            setSelectedOrder(order);
            setShowModal(true);
        }
    }

    const doneExchange = async()=>{
        props.setIsLoading(true);
        if(selectedOrder.type === 'Dépot'){
            await updateMgaMoney(mga_account.id!, {...mga_account, solde: mga_account.solde! + selectedOrder.amount_mga!});
            await updateUsdBalance(usdBalance.id!, {...usdBalance, balance: usdBalance.balance! - selectedOrder.amount!});
        }else{
            await updateMgaMoney(mga_account.id!, {...mga_account, solde: mga_account.solde! - selectedOrder.amount_mga!});
            await updateUsdBalance(usdBalance.id!, {...usdBalance, balance: usdBalance.balance! + selectedOrder.amount!});
        }
        await updateOrder(selectedOrder.id!, {...selectedOrder, is_payed: true});
        props.pushMsg!('Votre échange est en cours. Nous vous enverrons une notification une fois celui-ci terminé. NY - EXCHANGE MADA vous remercie.', MESSAGE_TYPE.INFORMATION);
        props.setIsLoading(false);
        await init();
        setShowModal(false);
    }

    useEffect(()=>{
        init();
    }, [])
    return (
        <div className='exchange-container'>
            <Helmet>
                <meta charSet='utf-8' name='description' content="Découvrez les détails de tous vos échanges ici."/>
                <title>Exchanges - Tous les échanges effectués</title>
                <link rel="canonical" href='https://nyexchangemada.com/me-exchanges'/>
            </Helmet>
            <h2 className='default-color'>Historiques d'échanges</h2>
            <div className='content'>
                {orders.length === 0 && (
                    <div className='none-exchange'>
                        AUCUNE ECHANGE POUR LE MOMENT
                    </div>
                )}
                {orders.length > 0 && (
                        <>
                        {orders.map((order: Order, key: number)=><ExchangeBox key={key} order={order} selectOrder={selectOrder} />)}
                        </>
                    )
                }
            </div>
            {showModal && (<div className='exchange-modal'>
                <div className='e-modal-btn-container'>
                    <button onClick={()=>setShowModal(false)}>
                        <IoClose size={25} />
                    </button>
                </div>
                <div className='e-modal-content'>
                    <div className='m-content'>
                        <span>{selectedOrder.type}</span>
                        <strong>{selectedOrder.amount} $</strong>
                    </div>
                    <div className='m-content'>
                        <span>Correspondance en AR</span>
                        <strong>{selectedOrder.amount_mga} AR</strong>
                    </div>
                    <div className='m-content'>
                        <span>Money</span>
                        <strong>{selectedOrder.money_usd}</strong>
                    </div>
                    <div className='m-content'>
                        <span>Compte</span>
                        <strong>{selectedOrder.wallet_usd_number}</strong>
                    </div>
                    <div className='m-content'>
                        <span>Compte Mobile</span>
                        <strong>{selectedOrder.wallet_mga_number}</strong>
                    </div>
                    <div className='m-content'>
                        <span>Nom du compte mobile</span>
                        <strong>{selectedOrder.wallet_mga_name}</strong>
                    </div>
                    {selectedOrder.is_payed && (<div className='m-content'>
                        <span>Status</span>
                        <strong className={selectedOrder.is_livred ? 'finish-text' :'not-finish-text'}>{selectedOrder.is_livred ? 'Férmé' : 'Encours'}</strong>
                    </div>)}
                    {!selectedOrder.is_payed && (<div className='not-payed-container'>
                    <p><span className="warning"><IoIosWarning size={20} /></span> Si le transfert est déjà effectué, n'oubliez pas de cliquer 👇</p>
                    <button type="button" className="done-btn" onClick={()=>doneExchange()}>DEJA EFFECTUE</button>
                    </div>)}
                </div>
            </div>
            )}
        </div>
    )
}

export default Exchange