import React, { useEffect, useState } from "react";
import '../css/Money.css'
import { UsdMoney } from "../models/global";
import { getFileById } from "../services/exchange";
interface propsType{
    Data: UsdMoney;
    toStart: (money: UsdMoney) => void;
}
const Money = (props: propsType)=>{
    const [photo, setPhoto] = useState<string>('');
    useEffect(()=>{
        getFileById(props.Data.photo).then(uri=>{
            if(uri) setPhoto(uri);
        })
    }, [])
    return(
        <div className="money">
            <img src={`https://nyexchangemada.com/api/${photo}`} alt="money"/>
            <h5>{`${props.Data.name} (${props.Data.type})`}</h5>
            <button onClick={()=>props.toStart(props.Data)}>Faire échange</button>
        </div>
    )
}

export default Money;