import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Exchange from './pages/Exchange';
import { MESSAGE_TYPE } from './models/global';
import About from './pages/About';
import Condition from './pages/Condition';
import Notifications from './pages/Notification';

export interface propsType{
  isLoading?: boolean;
  setIsLoading: (value: boolean) => void;
  parentVoid?: () => void;
  pushMsg?: (msg: string, type: MESSAGE_TYPE) => void;
}

const Navigation = (props: propsType) => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home setIsLoading={props.setIsLoading} parentVoid={props.parentVoid} pushMsg={props.pushMsg}/>} />
        <Route path="/me-exchanges" element={<Exchange pushMsg={props.pushMsg} setIsLoading={props.setIsLoading} />} />
        <Route path="/me-notifications" element={<Notifications setIsLoading={props.setIsLoading}/>} />
        <Route path="/about" element={<About setIsLoading={props.setIsLoading}/>} />
        <Route path="/condition" element={<Condition setIsLoading={props.setIsLoading}/>} />
      </Routes>
    </Router>
  );
};

export default Navigation;
