import React from "react";
import "../css/NotificationBox.css"
import { Notification } from "../models/global";
import { formatDate } from "../services/global";

interface propsType{
    notif: Notification;
    selectNotif: (notif: Notification) => void;
}

const NotificationBox = (props: propsType)=>{
    return (
        <div className={`notif-box ${!props.notif.is_seen ? 'bold' : ''}`}>
            <span>{formatDate(props.notif.created_at!)}</span>
            <span>{props.notif.title}</span>
            <button onClick={()=>props.selectNotif(props.notif)}>VOIR</button>
        </div>
    )
}


export default NotificationBox;