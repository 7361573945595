import React, { useEffect, useState } from 'react';
import './App.css';
import Navigation from './Navigation';
import { getCurrentYear } from './services/global';
import logo from './assets/logo.jpg'
import { IoNotifications } from "react-icons/io5";
import { MdOutlineCurrencyExchange } from "react-icons/md";
import axios from 'axios';
import ErrorModal from './components/ErrorModal';
import UserModal from './components/UserModal';
import { CodeDigit, LoginData, MESSAGE_TYPE, OrderNotifLen, User } from './models/global';
import Message from './components/Message';
import { createUser, forgetPass, getOrderNotifLen, guard, login, logout, validatePass } from './services/exchange';
import Spinner from './components/Spinner';
import timeout from '../src/assets/timeout.jpg';
function App() {
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [isUserModal, setIsUserModal] = useState<boolean>(false);
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [isDetail, setIsDetail] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [user, setUser] = useState<User>(localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!) : {});
  const [loginData, setLoginData] = useState<LoginData>({username: '', password: ''});
  const [msg, setMsg] = useState<string>('')
  const [msgType, setMsgType] = useState<MESSAGE_TYPE>(MESSAGE_TYPE.INFORMATION);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isTimeOut, setIsTimeOut] = useState<boolean>(false);
  const [orderNotifLen, setOrderNotifLen] = useState<OrderNotifLen>({notif: 0, order: 0});
  const [isForget, setIsForget] = useState<boolean>(false);
  const [codeDigit, setCodeDigit] = useState<CodeDigit>({DIGIT_1: '', DIGIT_2: '', DIGIT_3: '', DIGIT_4: '', DIGIT_5: ''})
  const [codeVerify, setCodeVerify] = useState<boolean>(false);
  
  //const navigate = useNavigation();

  const closeErrorModal = () => {
    setErrorMsg('')
  };

  const closeMsg = () => {
    setMsg('');
  }

  const closeUserModal = ()=>{
    setIsUserModal(false);
    if(isLogin) setIsLogin(false);
    if(isDetail) setIsDetail(false);
    if(codeVerify) setCodeVerify(false);
    if(isForget) setIsForget(false);
  }

  // axios.interceptors.response.use(
  //   (response) => {
  //     return response;
  //   },
  //   (error) => {
  //     if (error.response) {
  //       setMsg(error.response.data.detail);
  //       setMsgType(MESSAGE_TYPE.CRITICAL);
  //       return Promise.reject(error.response.data);
  //     } else if (error.request) {
  //       setMsg('Pas de réponse du serveur');
  //       setMsgType(MESSAGE_TYPE.CRITICAL);
  //       return Promise.reject('Pas de réponse du serveur');
  //     } else {
  //       setMsg(error.message);
  //       setMsgType(MESSAGE_TYPE.CRITICAL);
  //       return Promise.reject(error.message);
  //     }
  //   }
  // );

const init = ()=>{
  const userString = localStorage.getItem('user');
  const user: User = userString ? JSON.parse(userString) : {}
  getOrderNotifLen(user?.id || 0).then(_lens=>{
      if(_lens){
        setOrderNotifLen(_lens);
      }else{
        setOrderNotifLen({notif: 0, order: 0})
      }
    })
}

useEffect(()=>{
  guard().then(bool=>{
   if(!bool){
    if(localStorage.getItem("user"))localStorage.removeItem('user'); 
    }
  });
}, [])

useEffect(()=>{
    init();
    const intervalId = setInterval(() => {
    init();
    }, 10000);
    return () => clearInterval(intervalId);
}, [])

  useEffect(() => {
    const axiosInterceptor = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response) {
          if(error.response.data.detail ==="Accès refusé 👎" || error.response.data.detail === "Authentification est requis 👎" ||error.response.data.detail === "Authentification refusée 👎"){
              if(localStorage.getItem("user"))localStorage.removeItem('user');
          }
          else{
            setMsg(error.response.data.detail);
            setMsgType(MESSAGE_TYPE.CRITICAL);
          }
        } else if (error.request) {
          setMsg("Pas de réponse du serveur");
          setIsTimeOut(true);
        } else {
          setMsg(error.message);
          setMsgType(MESSAGE_TYPE.CRITICAL);
        }
        return Promise.reject(error);
      }
    );
    return () => {
      axios.interceptors.response.eject(axiosInterceptor);
    };
  }, []);

  // const setupAxiosInterceptors = () => {
  //   axios.interceptors.request.use(
  //     (config) => {
  //       const token = localStorage.getItem("token");
  //       if (token) {
  //         config.headers.Authorization = `Bearer ${token}`;
  //         config.headers.accept = "application/json";
  //       }
  //       return config;
  //     },
  //     (error) => {
  //       return Promise.reject(error);
  //     }
  //   );
  // };


  const userSubmit = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    if(!isLogin && !isUpdate) createUser(user).then(result=>{
      setIsLoading(false);
      if(result){
        setLoginData({...loginData, username: result.email!});
        setIsLogin(true);
      }
    })
    else if(isLogin){
      if(!isForget && !codeVerify) login(loginData).then(result=>{
        setIsLoading(false);
        if(result){
          localStorage.setItem('user', JSON.stringify({id: result.user.id, email: result.user.email, telephone: result.user.telephone, level: result.user.level, last_name: result.user.last_name}));
          localStorage.setItem('token', result.user.token);
          closeUserModal();
          setMsg('Vous êtes connecté, désormais vous pouvez bénéfier toutes les services');
          setMsgType(MESSAGE_TYPE.SUCCESS);
          setUser({id: result.user.id, email: result.user.email, telephone: result.user.telephone, level: result.user.level, last_name: result.user.last_name})
        }
      })
      else if(isForget && !codeVerify){
        forgetPass(loginData).then(msg=>{
          setIsLoading(false);
          if(msg){
            setMsg(msg);
            setMsgType(MESSAGE_TYPE.INFORMATION);
            setCodeVerify(true);
          }
        })
      }else{
        validatePass(`${codeDigit.DIGIT_1}${codeDigit.DIGIT_2}${codeDigit.DIGIT_3}${codeDigit.DIGIT_4}${codeDigit.DIGIT_5}`, loginData.username).then(isOk=>{
          setIsLoading(false);
          if(isOk){
            setMsg("Mot de passe modifié avec succès");
            setMsgType(MESSAGE_TYPE.SUCCESS);
            setIsForget(false);
            setCodeVerify(false);
            setCodeDigit({DIGIT_1: '', DIGIT_2: '', DIGIT_3: '', DIGIT_4: '', DIGIT_5: ''});
          }
        })
      }
    }
  }

  const openUserModal = () => {
    const _user: any = localStorage.getItem('user');
    if(_user){
      setUser(JSON.parse(_user));
      setIsDetail(true);
    }
    setIsUserModal(true);
  }

  const pushMsg = (_msg: string, type: MESSAGE_TYPE) => {
    setMsg(_msg);
    setMsgType(type);
  }

  const toLogOut = ()=>{
    setIsLoading(true);
    logout().then(result=>{
      setIsLoading(false);
      localStorage.removeItem('user');
      setUser({});
      setLoginData({username: '', password: ''});
      setMsg('Vous êtes déconnecté !');
      setMsgType(MESSAGE_TYPE.WARNING);
      closeUserModal();
    })
  }

  return (
    <>
      {!isTimeOut && (
        <>
      <div className='header'>
        <a href='/' style={{marginLeft: '2%'}}>
          <img className='logo' src={"https://nyexchangemada.com/api/static/1705495475.jpg" || logo} alt='logo'/>
        </a>
        <a href='/' style={{textDecoration: 'none'}}>
        <h3>NY EXCHANGE MADA</h3>
        </a>
        <div className='user-box'>
          <a href='/me-exchanges'>
          <button className=''>
            <MdOutlineCurrencyExchange size={20}/> 
            {orderNotifLen.order > 0 && (<div>{orderNotifLen.order}</div>)}
          </button>
          </a>
          <a href='/me-notifications'>
          <button className=''>
            <IoNotifications size={20}/>
            {orderNotifLen.notif > 0 && (<div>{orderNotifLen.notif}</div>)}
          </button>
          </a>
          <img onClick={()=>{
            openUserModal()
          }} src='https://nyexchangemada.com/api/static/1700140428.png' alt='user'/>
        </div>
      </div>
      <div className='banner'>
        <img src='https://nyexchangemada.com/api/static/1705555461.jpg' alt='banner'/>
      </div>
      <Navigation isLoading={isLoading} setIsLoading={setIsLoading} parentVoid={openUserModal} pushMsg={pushMsg}/>
      <div className='footer'>
        <div className='copy-right'><p>&copy; {getCurrentYear()} NY EXCHANGE MADA. Tous droits réservés.</p></div>
        <div className='condition-about'>
          <a href='/about'>
            <button>A propos de nous</button>
          </a>
          <div className='separation'></div>
          <a href='/condition'>
            <button>Termes et conditions</button>
          </a>
        </div>
      </div>
      {isUserModal && (<UserModal
        isDetail={isDetail} isLogin={isLogin} loginData={loginData} userData={user} setIsDetail={setIsDetail} setIsLogin={setIsLogin} setLoginData={setLoginData} setUserData={setUser}
        isUpdate={isUpdate} setIsUpdate={setIsUpdate}
        closeModal={closeUserModal}
        sumbit={userSubmit}
        logOut={toLogOut}
        isForget={isForget}
        setIsForget={setIsForget}
        codeDigit={codeDigit}
        setCodeDigit={setCodeDigit}
        isVerifyCode={codeVerify}
        setIsVerifyCode={setCodeVerify}
      />)}
      {errorMsg && (
        <ErrorModal message={errorMsg} onClose={closeErrorModal} />
      )}
      {
        msg && (<Message msg={msg} type={msgType} close={closeMsg} />)
      }
      {isLoading && (<Spinner/>)}
      </>)}
      {isTimeOut && (
        <div className='timeout-container'>
          <img src={timeout} alt='timeout'/>
        </div>
      )}
    </>
  );
}

export default App;
