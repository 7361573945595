import React, { useState } from "react";
import '../css/UserModal.css'
import { IoClose } from "react-icons/io5";
import { FaEye, FaEyeSlash, FaPhoneAlt, FaUser } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { IoIosLock } from "react-icons/io";
import { CodeDigit, LoginData, User } from "../models/global";
import { checkPhoneNumber } from "../services/exchange";

interface propsType {
    isLogin: boolean;
    isDetail: boolean;
    isUpdate: boolean;
    loginData: LoginData;
    userData: User;
    setIsLogin: (value: boolean) => void;
    setIsDetail: (value: boolean) => void;
    setIsUpdate: (value: boolean) => void;
    setLoginData: (data: LoginData) => void;
    setUserData: (data: User) => void;
    closeModal: () => void;
    sumbit: (e: any) => void;
    logOut: () => void;
    isForget: boolean;
    setIsForget: (value: boolean) => void;
    isVerifyCode: boolean;
    setIsVerifyCode: (value: boolean) => void;
    codeDigit: CodeDigit;
    setCodeDigit: (value: CodeDigit) => void;
}

const UserModal = (props: propsType) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [phoneNotValid, setPhoneNotValid] = useState<string>('')

    // id?: number; 
    // last_name?: string;
    // first_name?: string;
    // email?: string;
    // telephone?: string;
    // level?: number;
    // fb_id?: string;
    // password?: string;

    const handelChange = (target: any) => {
        if(!props.isLogin){
            props.setUserData({...props.userData, [target.name]: target.value});
            if(target.name === 'telephone') setPhoneNotValid(checkPhoneNumber(target.value));
        } else if(props.isLogin){
            props.setLoginData({...props.loginData, [target.name]: target.value})
        }
    }

    const handleChangeDigit = (target: any) => {
        const name: string = target.name;
        const digit_indice: number = +name.split('_')[1];
        if(Number(target.value) && target.value.length === 5){
            props.setCodeDigit({DIGIT_1: target.value[0], DIGIT_2: target.value[1], DIGIT_3: target.value[2], DIGIT_4: target.value[3], DIGIT_5: target.value[4]});
        }
        else if(Number(target.value)){
            if(digit_indice < 5 && target.value.length > 1){
                props.setCodeDigit({...props.codeDigit, [`DIGIT_${digit_indice}`]: target.value.slice(0, 1), [`DIGIT_${digit_indice + 1}`]: target.value.slice(1, )})
            }else{
                props.setCodeDigit({...props.codeDigit, [`DIGIT_${digit_indice}`]: target.value});
            }
        }else if(!target.value){
            props.setCodeDigit({...props.codeDigit, [`DIGIT_${digit_indice}`]: target.value});
        }
    }

    return(
        <div className="user-modal-container">
            <div className="user-modal-box">
                <button className="cancel-usd-modal" onClick={()=>props.closeModal()}>
                    <IoClose size={25}/>
                </button>
                <form onSubmit={props.sumbit}>
                    {!localStorage.getItem('user') && !props.isLogin && !props.isDetail && !props.isVerifyCode && (<>
                    <div className="user-in-box">
                        <div className="user-icon-box">
                        <FaUser size={20} />
                        </div>
                        <input type="text" placeholder="Nom" value={props.userData.last_name} name="last_name" onChange={(e)=>handelChange(e.target)}/>
                    </div>
                    <div className="user-in-box">
                        <div className="user-icon-box">
                        <MdEmail size={20} />
                        </div>
                        <input type="email" placeholder="Email" value={props.userData.email} name="email" onChange={(e)=>handelChange(e.target)}/>
                    </div>
                    <div className="user-in-box">
                        <div className="user-icon-box">
                        <FaPhoneAlt size={20} />
                        </div>
                        <input type="text" placeholder="Téléphone" value={props.userData.telephone} name="telephone" onChange={(e)=>handelChange(e.target)}/>
                    </div>
                    {phoneNotValid && (<div className="phone-not-valid">{phoneNotValid}</div>)}
                    <div className="user-in-box">
                        <div className="user-icon-box">
                        <IoIosLock size={20}/>
                        </div>
                        <input type={!isVisible ? 'password' : 'text'} placeholder="Mot de passe" value={props.userData.password} name="password" onChange={(e)=>handelChange(e.target)}/>
                        <button type="button" className="eye-btn"
                            onClick={()=>{setIsVisible(!isVisible)}}
                        >
                        {!isVisible && (<FaEye size={20} />)}
                        {isVisible && (<FaEyeSlash size={20} />)}
                        </button>
                    </div>
                    {!phoneNotValid && props.userData.last_name && props.userData.email && props.userData.telephone && props.userData.password && (<button type="submit" className="sign-btn">S'inscrire</button>)}
                    {(phoneNotValid || !props.userData.last_name || !props.userData.email || !props.userData.telephone || !props.userData.password) && (<button type="button" className="sign-btn disable">S'inscrire</button>)}
                    <button onClick={()=>props.setIsLogin(true)} type="button" className="sign-btn">J'ai déjà un compte</button>
                    </>)}
                    {props.isLogin && !props.isVerifyCode &&(
                        <>
                            {props.isForget && (<div className="forget-pass-title"><strong>Changement de mot de passe</strong></div>)}
                            <div className="user-in-box">
                                <div className="user-icon-box">
                                    <MdEmail size={20} />
                                </div>
                                <input type="email" placeholder="Email" value={props.loginData.username}
                                 name="username"   onChange={(e)=>handelChange(e.target)}
                                />   
                            </div>
                            <div className="user-in-box">
                                <div className="user-icon-box">
                                    <IoIosLock size={20}/>
                                </div>
                                <input type={!isVisible ? 'password' : 'text'} placeholder={props.isForget? "Nouveau mot de passe":"Mot de passe"}
                                value={props.loginData.password} name="password" onChange={(e)=>handelChange(e.target)}
                                />
                                <button type="button" className="eye-btn"
                                    onClick={()=>{setIsVisible(!isVisible)}}
                                >
                                {!isVisible && (<FaEye size={20} />)}
                                {isVisible && (<FaEyeSlash size={20} />)}
                                </button>
                            </div>
                            {!props.isForget && (<div className="forget-pass"><span onClick={()=>props.setIsForget(true)}>? Mot de passe oublié</span></div>)}
                    {props.loginData.username && props.loginData.password && (<button type="submit" className="sign-btn">{props.isForget?'ENVOYER':'CONNEXION'}</button>)}
                    {(!props.loginData.username || !props.loginData.password) && (<button type="submit" className="sign-btn disable">{props.isForget?'ENVOYER': 'CONNEXION'}</button>)}
                    </>
                    )}
                    {props.isDetail && !props.isVerifyCode && (
                        <>
                            <div className="user-detail-container">
                                <img src='https://nyexchangemada.com/api/static/1700140428.png' alt='user'/>
                                <p>{props.userData.first_name}</p>
                                <p><FaPhoneAlt size={20} /> <span>{props.userData.telephone}</span></p>
                                <p><MdEmail size={20} /> <span>{props.userData.email}</span></p>
                            </div>
                            {/* <button type="button" className="sign-btn">MODIFIER</button> */}
                            <button type="button" className="sign-btn" onClick={()=>props.logOut()}>DECONNEXION</button>
                        </>
                    )}
                    {props.isVerifyCode && !props.isDetail && (<div className="code-verification">
                        <strong>CODE DE VERIFICATION</strong>
                        <div className="digit-container">
                            <input type="text" value={props.codeDigit.DIGIT_1} onChange={(e)=>handleChangeDigit(e.target)} name="DIGIT_1"/>
                            <input type="text" value={props.codeDigit.DIGIT_2} onChange={(e)=>handleChangeDigit(e.target)} name="DIGIT_2"/>
                            <input type="text" value={props.codeDigit.DIGIT_3} onChange={(e)=>handleChangeDigit(e.target)} name="DIGIT_3"/>
                            <input type="text" value={props.codeDigit.DIGIT_4} onChange={(e)=>handleChangeDigit(e.target)} name="DIGIT_4"/>
                            <input type="text" value={props.codeDigit.DIGIT_5} onChange={(e)=>handleChangeDigit(e.target)} name="DIGIT_5"/>
                        </div>
                        {props.codeDigit.DIGIT_1 && props.codeDigit.DIGIT_2 && props.codeDigit.DIGIT_3 && props.codeDigit.DIGIT_4 && props.codeDigit.DIGIT_5 &&(<button type="submit" className="not-disable">VERIFIER</button>)}
                        {!(props.codeDigit.DIGIT_1 && props.codeDigit.DIGIT_2 && props.codeDigit.DIGIT_3 && props.codeDigit.DIGIT_4 && props.codeDigit.DIGIT_5) && (<button type="button" className="disable">VERIFIER</button>)}
                    </div>)}
                </form>
            </div>
        </div>
    )
}

export default UserModal;