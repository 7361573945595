import { MGA_NAME } from "../enums/global";

export const SUPPER_PASS = 'jamsbond007'
export interface balance {
    payeer: number;
    pm: number;
    crypto: number;
    m_vola: number;
    orange_money: number;
    airtel_money: number;
}

export interface cours{
    depot_fiat: number;
    depot_crypto: number;
    retrait_fiat_inf_500: number;
    retrait_fiat_sup_500: number;
    retrait_crypto_inf_1000: number;
    retrait_crypto_sup_1000: number;
}

export interface UsdMoney{
    id: number;
    name: string;
    wallet_number: string;
    wallet_id: string;
    type: string;
    photo: number;
}

export interface Order{
    id?: number;
    user_id?: number;
    type?: string;
    money_mga?: MGA_NAME;
    money_usd?: string;
    wallet?: string;
    wallet_mga_number?: string;
    wallet_mga_name?: string;
    wallet_usd_number?: string;
    amount?: number;
    amount_mga?: number;
    is_finish?: boolean;
    is_payed?: boolean;
    is_livred?: boolean;
    user_name?: string;
    created_at?: string;
}

export interface User{
    id?: number; 
    last_name?: string;
    first_name?: string;
    email?: string;
    telephone?: string;
    level?: number;
    fb_id?: string;
    password?: string;
}

export interface LoginData{
    username: string;
    password: string;
}

export enum MESSAGE_TYPE{
    INFORMATION='INFORMATION',
    SUCCESS='SUCCESS',
    WARNING='WARNING',
    CRITICAL='CRITICAL'
}

export interface MgaMoney{
    id?: number;
    name?: string;
    wallet_number?: string;
    wallet_name?: string;
    solde?: number;
    photo?: number; 
}

export interface UsdBalance{
    id?: number;
    name?: string;
    balance?: number;
}

export interface OrderNotifLen{
    order: number;
    notif: number;
}

export interface Notification{
    id?: number;
    title?: string;
    content?: string;
    file_id?: number;
    user_id?: number;
    order_id?:number;
    is_seen?: boolean;
    created_at?: string;
}

export interface CodeDigit{
    DIGIT_1: string;
    DIGIT_2: string;
    DIGIT_3: string;
    DIGIT_4: string;
    DIGIT_5: string;
}