import React, { useEffect, useRef } from 'react';
import $ from 'jquery';
import "../css/ErrorModal.css";

interface ErrorModalProps {
  message: string;
  onClose: () => void;
}

const ErrorModal: React.FC<ErrorModalProps> = ({ message, onClose }) => {
  const modalRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (modalRef.current) {
      $(modalRef.current).fadeIn();
    }
    const autoCloseTimeout = setTimeout(() => {
      closeErrorModal();
    }, 10000);
    return () => {
      clearTimeout(autoCloseTimeout);
    };
  }, []);

  const closeErrorModal = () => {
    if (modalRef.current) {
      $(modalRef.current).fadeOut(() => {
        onClose();
      });
    }
  };

  return (
    <div ref={modalRef} className="error-modal">
      <div className="modal-content">
        <p>{message}</p>
      </div>
    </div>
  );
};

export default ErrorModal;
