import React from "react";
import "../css/ExchangeBox.css"
import { Order } from "../models/global";
import { formatDate } from "../services/global";

interface propsType{
    order: Order;
    selectOrder: (order: Order) => void;
}
const ExchangeBox = (props: propsType) => {
    return(
        <div className="e-box-container">
            <div className={`status ${!props.order.is_payed ? 'not-payed' : props.order.is_livred ? 'finish':'not-finish' }`}></div>
            <span>{formatDate(props.order.created_at!)}</span>
            <span>{props.order.type}</span>
            <span>{props.order.amount} $</span>
            <button onClick={()=>props.selectOrder(props.order)}>DETAIL</button>
        </div>
    )
}

export default ExchangeBox;