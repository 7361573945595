import axios from "axios";
import {
  LoginData,
  MgaMoney,
  Notification,
  Order,
  OrderNotifLen,
  SUPPER_PASS,
  UsdBalance,
  UsdMoney,
  User,
  balance,
  cours,
} from "../models/global";
import { formatNumber } from "./global";
import { MGA_NAME } from "../enums/global";
import { API } from "../env";

// axios.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     if (error.response) {
//       console.error('Erreur de réponse du serveur:', error.response.data);
//       return Promise.reject(error.response.data);
//     } else if (error.request) {
//       console.error('Pas de réponse du serveur');
//       return Promise.reject('Pas de réponse du serveur');
//     } else {
//       console.error('Erreur de configuration de la requête', error.message);
//       return Promise.reject(error.message);
//     }
//   }
// );

export const getSystemState = async (): Promise<boolean | undefined> => {
  try {
    return (await axios.get<boolean>(`${API.NY_API}/auth/system`)).data;
  } catch (err) {
    console.log(err);
    return undefined;
  }
};
export const getOrderNotifLen = async (
  user_id: number
): Promise<OrderNotifLen | undefined> => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
  };
  try {
    return (
      await axios.get<OrderNotifLen>(
        `${API.NY_API}/user/order-notif-len/${user_id}`,
        { headers }
      )
    ).data;
  } catch (err) {
    console.log(err);
    return undefined;
  }
};

export const getAllBalance = async (): Promise<balance | undefined> => {
  try {
    return (await axios.get<balance>(`${API.NY_API}/user/all-balance`)).data;
  } catch (err) {
    console.log(err);
    return undefined;
  }
};

export const getAllCours = async (): Promise<cours | undefined> => {
  try {
    return (await axios.get<cours>(`${API.NY_API}/user/all-course`)).data;
  } catch (err) {
    console.log(err);
    return undefined;
  }
};

export const getAllMoney = async (): Promise<UsdMoney[] | undefined> => {
  try {
    return (await axios.get<UsdMoney[]>(`${API.NY_API}/user/usd-money-all`))
      .data;
  } catch (err) {
    return undefined;
  }
};

export const getAllOrder = async (
  user_id: number
): Promise<Order[] | undefined> => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
  };
  try {
    return (
      await axios.get<Order[]>(
        `${API.NY_API}/crud/orders/all-by-user/${user_id}`,
        { headers }
      )
    ).data;
  } catch (err) {
    return undefined;
  }
};

export const getAllNotif = async (
  user_id: number
): Promise<Notification[] | undefined> => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
  };
  try {
    return (
      await axios.get<Notification[]>(
        `${API.NY_API}/crud/notification/by-user/${user_id}`,
        { headers }
      )
    ).data;
  } catch (err) {
    return undefined;
  }
};

export const updateNotif = async (
  id: number,
  notif: Notification
): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
  };
  delete notif.id;
  try {
    return (
      await axios.put(`${API.NY_API}/crud/notification/${id}`, notif, {
        headers,
      })
    ).data;
  } catch (err) {
    return undefined;
  }
};

export const getFileById = async (id: number): Promise<string | undefined> => {
  try {
    return (
      await axios.get<string>(
        `${API.NY_API}/user/file/${id}?supper_pass=${SUPPER_PASS}`
      )
    ).data;
  } catch (err) {
    console.log(err);
    return undefined;
  }
};

export const createUser = async (user: User): Promise<User | undefined> => {
  try {
    return (
      await axios.post<User>(`${API.NY_API}/crud/users/${SUPPER_PASS}`, user)
    ).data;
  } catch (err) {
    return undefined;
  }
};

export const forgetPass = async (
  data: LoginData
): Promise<string | undefined> => {
  try {
    return (
      await axios.post<string>(
        `${API.NY_API}/crud/users/forget-pass/${SUPPER_PASS}`,
        { email: data.username, password: data.password }
      )
    ).data;
  } catch (err) {
    return undefined;
  }
};

export const validatePass = async (
  code: string,
  email: string
): Promise<boolean | undefined> => {
  try {
    return (
      await axios.get<boolean>(
        `${API.NY_API}/crud/users/validate-pass/${code}?email=${email}`
      )
    ).data;
  } catch (err) {
    return undefined;
  }
};

export const login = async (user: LoginData): Promise<any> => {
  const body = new URLSearchParams();
  body.set("username", user.username);
  body.set("password", user.password);
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };
  try {
    return (
      await axios.post<any>(`${API.NY_API}/auth/login`, body.toString(), {
        headers,
      })
    ).data;
  } catch (err) {
    return undefined;
  }
};

export const logout = async (): Promise<boolean> => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
  };
  try {
    return (await axios.get<boolean>(`${API.NY_API}/auth/logout`, { headers }))
      .data;
  } catch (err) {
    return false;
  }
};

export const guard = async (): Promise<boolean> => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
  };
  try {
    return (await axios.get<boolean>(`${API.NY_API}/auth/guard`, { headers }))
      .data;
  } catch (err) {
    return false;
  }
};

export const createOrder = async (order: Order): Promise<Order | undefined> => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
  };
  try {
    return (
      await axios.post<Order>(`${API.NY_API}/crud/orders`, order, { headers })
    ).data;
  } catch (err) {
    return undefined;
  }
};
export const updateOrder = async (
  id: number,
  order: Order
): Promise<string | undefined> => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
  };
  delete order.id;
  try {
    return (
      await axios.put<string>(`${API.NY_API}/crud/orders/${id}`, order, {
        headers,
      })
    ).data;
  } catch (err) {
    return undefined;
  }
};

export const getUsdByName = async (
  name: string
): Promise<UsdMoney | undefined> => {
  try {
    return (
      await axios.get(
        `${API.NY_API}/user/usd-money-by-name/${name}?supper_pass=${SUPPER_PASS}`
      )
    ).data;
  } catch (err) {
    return undefined;
  }
};

export const getMgaMoney = async (
  name: string
): Promise<MgaMoney | undefined> => {
  try {
    return (
      await axios.get<MgaMoney>(
        `${API.NY_API}/user/mga-money/${name}?supper_pass=${SUPPER_PASS}`
      )
    ).data;
  } catch (err) {
    return undefined;
  }
};

export const updateMgaMoney = async (
  id: number,
  money: MgaMoney
): Promise<boolean | undefined> => {
  delete money.id;
  try {
    return (
      await axios.put<boolean>(
        `${API.NY_API}/user/mga-money/${id}?supper_pass=${SUPPER_PASS}`,
        money
      )
    ).data;
  } catch (err) {
    return undefined;
  }
};

export const getUsdBalance = async (
  name: string
): Promise<UsdBalance | undefined> => {
  try {
    return (
      await axios.get<UsdBalance>(
        `${API.NY_API}/user/usd-balance/${name}?supper_pass=${SUPPER_PASS}`
      )
    ).data;
  } catch (err) {
    return undefined;
  }
};

export const updateUsdBalance = async (
  id: number,
  balance: UsdBalance
): Promise<boolean | undefined> => {
  delete balance.id;
  try {
    return (
      await axios.put<boolean>(
        `${API.NY_API}/user/usd-balance/${id}?supper_pass=${SUPPER_PASS}`,
        balance
      )
    ).data;
  } catch (err) {
    return undefined;
  }
};

interface AccountType {
  name: string;
  number: string;
}

export const checkAccountNumber = (account: AccountType): string => {
  if (account.name === "USD PAYEER") {
    const spiliting = account.number.split("P");
    if (!Number(spiliting[1]))
      return "Numéro du compte payeer invalid (ex: Pxxxxxxx, x: chiffre)";
  }
  if (account.name === "USD PERFECT MONEY") {
    const spiliting = account.number.split("U");
    if (!Number(spiliting[1]))
      return "Numéro du compte PM invalid (ex: Uxxxxxxxx, x: chiffre)";
  }
  if (account.name === "BINANCE") {
    if (!Number(account.number))
      return "ID binance invalid (ex: xxxxxxxxx, x: chiffre)";
  }
  if (account.name === "OTHER") {
    if (account.number.includes(" ")) return `Numéro du compte USDT invalid`;
    const spliting = account.number.split("0x").join("");
    if (spliting.length < 20) return `Numéro du compte USDT invalid`;
    if (Number(spliting)) return `Numéro du compte USDT invalid`;
    var isExist = false;
    var isEmail = false;
    for (let i = 0; i < spliting.length; i++) {
      if (spliting[i] === "@") {
        isEmail = true;
        break;
      }
    }
    for (let i = 0; i < spliting.length; i++) {
      if (spliting[i] === "@") {
        isEmail = true;
        break;
      } else if (
        spliting[i] === "0" ||
        spliting[i] === "1" ||
        spliting[i] === "2" ||
        spliting[i] === "3" ||
        spliting[i] === "4" ||
        spliting[i] === "5" ||
        spliting[i] === "6" ||
        spliting[i] === "7" ||
        spliting[i] === "8" ||
        spliting[i] === "9"
      ) {
        isExist = true;
        break;
      }
    }
    if (!isExist || isEmail) return `Numéro du compte USDT invalid`;
  }
  return "";
};

export const checkPhoneNumber = (phone: string): string => {
  phone = phone.split(" ").join("");
  if (!Number(phone)) return `Numéro du téléphone invalid (ex: 03x xx xxx xx)`;
  if (phone.length !== 10)
    return `Numéro du téléphone invalid (ex: 03x xx xxx xx)`;
  if (
    phone[0] !== "0" ||
    phone[1] !== "3" ||
    (phone[2] !== "2" &&
      phone[2] !== "4" &&
      phone[2] !== "8" &&
      phone[2] !== "3")
  )
    return `Numéro du téléphone invalid (ex: 03x xx xxx xx)`;
  return "";
};

export const createMgaMoney = (phone: string): MGA_NAME => {
  phone = phone.split(" ").join("");
  const header = phone[0] + phone[1] + phone[2];
  if (header === "034" || header === "038") return MGA_NAME.TELMA;
  if (header === "032") return MGA_NAME.ORANGE;
  return MGA_NAME.AIRTEL;
};

export const calculateMgaAmount = (
  order: Order,
  money: UsdMoney,
  balance: balance,
  cours: cours
): number => {
  if (order.type === "Dépot") {
    console.log("value: ", order.amount);
    const selectedCours =
      order.money_usd === "Payeer" || order.money_usd === "USD PERFECT MONEY"
        ? cours.depot_fiat
        : cours.depot_crypto;
    if (order.wallet! === "OTHER") {
      if (order.money_usd === "BTC" || order.money_usd === "ETH")
        return order.amount! * selectedCours + 5 * selectedCours;
      else if (order.money_usd === "USDT TRC20" && order.amount! < 150)
        return order.amount! * selectedCours + selectedCours;
      else if (
        (order.money_usd === "TRX" ||
          order.money_usd === "BTC BEP20" ||
          order.money_usd === "USDT BEP20" ||
          order.money_usd === "ETH BEP20" ||
          order.money_usd === "BNB BEP20" ||
          order.money_usd === "LTC" ||
          order.money_usd === "DOGE") &&
        order.amount! < 50
      )
        return order.amount! * selectedCours + 0.3 * selectedCours;
      else return order.amount! * selectedCours;
    } else return order.amount! * selectedCours;
  } else {
    let selectedCours = 0;
    if (money.type === "FIAT")
      selectedCours =
        order.amount! < 500
          ? cours.retrait_fiat_inf_500
          : cours.retrait_fiat_sup_500;
    else
      selectedCours =
        order.amount! < 1000
          ? cours.retrait_crypto_inf_1000
          : cours.retrait_crypto_sup_1000;
    return order.amount! * selectedCours;
  }
};

export const checkAmount = (
  order: Order,
  money: UsdMoney,
  balance: balance,
  cours: cours
): string => {
  if (order.type === "Dépot") {
    if (money.type === "CRYPTO") {
      if (balance.crypto < order.amount!)
        return `Pour le moment solde disponible en ${
          order.money_usd
        } est ${formatNumber(balance.crypto)} $`;
      if (order.amount! < 10) return `Dépot minimum en ${money.name} est 10 $`;
    } else {
      const fiatBalance: number =
        money.name === "Payeer" ? balance.payeer : balance.pm;
      if (order.amount! > fiatBalance)
        return `Pour le moment solde disponible en ${money.name} est ${fiatBalance} $`;
      if (order.amount! < 5) return `Dépot minimum en ${money.name} est 5 $`;
    }
  } else {
    const balance_mga =
      order.money_mga === MGA_NAME.TELMA
        ? balance.m_vola
        : order.money_mga === MGA_NAME.ORANGE
        ? balance.orange_money
        : balance.airtel_money;
    if (balance_mga < order.amount_mga!)
      return `Pour le moment solde disponible en ${
        order.money_mga
      } est ${formatNumber(balance_mga)} AR`;
    if (money.type === "CRYPTO") {
      if (order.amount! < 10)
        return `Retrait minimum en ${money.name} est 10 $`;
    } else {
      if (order.amount! < 5) return `Retrait minimum en ${money.name} est 5 $`;
    }
  }
  return "";
};
