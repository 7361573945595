export const getCurrentYear = ()=>{
  return new Date().getFullYear();
}

export const formatNumber = (number: number): string => {
    let numStr = number.toString();
    let parts = numStr.split('.');
    let integerPart = parts[0];
    let formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    let formattedNumber = parts.length > 1 ? formattedInteger + '.' + parts[1] : formattedInteger;
    return formattedNumber;
}

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text)
    .then(() => {
      setTimeout(() => {
      }, 2000);
    })
    .catch((error) => {
      console.error("Erreur lors de la copie dans le presse-papiers :", error);
    });
};

// export const getMgaCode = (number: string, amount: number): string =>{
//   const tel = number.split(' ').join('')
//     if(tel[2]==="2"){
//         return `#144*1*1*${number}*${number}*${amount}*2#`
//     }
//     if(tel[2]==="4" || tel[2]==="8"){
//         return `#111*1*2*${number}*${amount}*2#`
//     }
//     if(tel[2]=="3"){
//         return `veuillez transferer ${sum}AR vers notre compte airtemoney suivant:\n👉Numéro du compte: ${c_m_m.tel},\n👉Nom du compe: ${c_m_m.name}`
//     }
// } 


export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  date.setHours(date.getHours() + 3);
  const jour = String(date.getDate()).padStart(2, '0');
  const mois = String(date.getMonth() + 1).padStart(2, '0');
  const annee = date.getFullYear();
  const heures = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${jour}/${mois}/${annee} ${heures}:${minutes}`;
}