import React from 'react';
import { formatNumber } from '../services/global';
import '../css/Cours.css'
import { ImStatsDots } from "react-icons/im";
import { cours } from '../models/global';

interface coursType{
    CoursData: cours
}

const Cours = (props: coursType)=>{
    return(
        <div className='cours'>
            <div className='cours-title'>
                <ImStatsDots size={20}/> 
                <span>COURS D'ECHANGE (pour 1 USD)</span>
            </div>
            <div className='cours-container'>
                <div className='c-c-header'>
                    <span>DEPOT FIAT</span>
                    <div></div>
                    <span>DEPOT CRYPTO</span>
                </div>
                <div className='c-content'>
                    <span>{formatNumber(props.CoursData.depot_fiat)} AR</span>
                    <div></div>
                    <span>{formatNumber(props.CoursData.depot_crypto)} AR</span>
                </div>
            </div>
            <div className='cours-container'>
                <div className='c-c-header'>
                    <span>RETRAIT FIAT</span>
                    <div></div>
                    <span>RETRAIT CRYPTO</span>
                </div>
                <div className='c-content'>
                    <span> {`< 500 USD -> ${formatNumber(props.CoursData.retrait_fiat_inf_500)} AR`}</span>
                    <div></div>
                    <span> {`< 1000 USD -> ${formatNumber(props.CoursData.retrait_crypto_inf_1000)} AR`}</span>
                </div>
                <div className='c-content'>
                    <span> {`>= 500 USD -> ${formatNumber(props.CoursData.retrait_fiat_sup_500)} AR`}</span>
                    <div></div>
                    <span> {`>= 1000 USD -> ${formatNumber(props.CoursData.retrait_crypto_sup_1000)} AR`}</span>
                </div>
            </div>
        </div>
    )
}

export default Cours;